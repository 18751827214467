<div class="h-screen flex flex-col">
  <!-- Header -->
  <!--app-header class="flex-none"></app-header-->

  <!-- Main Content -->
  <div class="main-container flex-grow flex justify-center items-center">
    <router-outlet></router-outlet>
  </div>

  <!-- Loader (se necessario) -->
  <div *ngIf="stateManager.isLoading()" class="absolute inset-0 flex justify-center items-center bg-white/50">
    <app-loader></app-loader>
  </div>

  <!-- Footer -->
  <!--app-footer class="flex-none"></app-footer-->
</div>
